/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { LogLevel, Configuration, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';

export const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  velaApi: {
    endpoint: '/api/private/',
    scopes: ['https://VelaSuiteTest.onmicrosoft.com/tasks-api/ReadAccess', 'openid'],
  },
};
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 *
 *
 */
export const b2cPoliciesTest = {
  names: {
    signUpSignIn: 'B2C_1_EntraID_signupin_flow',
    resetPassword: 'B2C_1_reset_v3',
    editProfile: 'B2C_1_edit_profile_v2',
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://VelaSuiteTest.b2clogin.com/VelaSuiteTest.onmicrosoft.com/B2C_1_EntraID_signupin_flow',
    },
    resetPassword: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
    },
    editProfile: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
    },
  },
  authorityDomain: 'VelaSuiteTest.b2clogin.com',
};

export const b2cPolicies = {
  names: {
    signIn: 'B2C_1_vela_signin_flow',
    resetPassword: 'B2C_1_vela_reset_password',
  },
  authorities: {
    signIn: {
      authority: 'https://VelaSuiteTest.b2clogin.com/VelaSuiteTest.onmicrosoft.com/B2C_1_vela_signin_flow',
    },
    resetPassword: {
      authority: 'https://VelaSuiteTest.b2clogin.com/VelaSuiteTest.onmicrosoft.com/B2C_1_vela_reset_password',
    },
  },
  authorityDomain: 'VelaSuiteTest.b2clogin.com',
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfigTest: Configuration = {
  auth: {
    clientId: '1567b183-85e9-4010-a664-135636f08b56', // This is the ONLY mandatory field that you need to supply.
    authority: b2cPoliciesTest.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPoliciesTest.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/dashboard',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },
  },
};

export const msalConfig: Configuration = {
  auth: {
    clientId: '1567b183-85e9-4010-a664-135636f08b56', // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/dashboard',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },
  },
};

export const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: protectedResources.velaApi.scopes,
    extraQueryParameters: { ui_locales: 'it' },
  },
};

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map([[protectedResources.velaApi.endpoint, protectedResources.velaApi.scopes]]),
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: { scopes: string[] } = {
  scopes: [],
};

export function createMsalConfig(flow: string, authorityDomain: string): Configuration {
  return {
    auth: {
      clientId: '1567b183-85e9-4010-a664-135636f08b56',
      authority: flow,
      knownAuthorities: [authorityDomain],
      redirectUri: '/dashboard',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          console.log(message);
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  };
}
