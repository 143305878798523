import { TranslateService } from '@ngx-translate/core';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { WaitingLayerComponent } from './shared/components/waiting-layer/waiting-layer.component';
import { UserService } from './core/services/user/user.service';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

import { loadCldr } from '@syncfusion/ej2-base';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/it/ca-gregorian.json';
import * as numbers from 'cldr-data/main/it/numbers.json';
import * as timeZoneNames from 'cldr-data/main/it/timeZoneNames.json';

// @ts-ignore
loadCldr(numberingSystems['default'], gregorian['default'], numbers['default'], timeZoneNames['default']);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [WaitingLayerComponent, RouterOutlet],
})
export class AppComponent {
  title = 'Velasuite';
  #userService = inject(UserService);

  constructor(translate: TranslateService) {
    registerLocaleData(localeIt);
    this.#userService.setUserProfile().subscribe(userProfile => {
      // if (userProfile && userProfile.language) {
      //   translate.use(userProfile.language);
      //   return;
      // }
      translate.setDefaultLang('en-US');
    });
  }
}
