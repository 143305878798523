import { Component } from '@angular/core';
import { WaitingService } from '../../services/waiting.service';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'vela-waiting-layer',
    templateUrl: './waiting-layer.component.html',
    styleUrls: ['./waiting-layer.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class WaitingLayerComponent {
  constructor(public waitingService: WaitingService) {}
}
