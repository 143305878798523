/// <reference types="@angular/localize" />

import { registerLicense } from '@syncfusion/ej2-base';
import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';

import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalInterceptor,
  MsalGuard,
  MsalService,
  MsalModule,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { createMsalConfig, msalGuardConfig, msalInterceptorConfig } from './app/auth.config';
import { AppComponent } from './app/app.component';
import { ToastrModule } from 'ngx-toastr';
import { setupTranslations } from './app/core/services/lang-loader/custom-lang-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { bootstrapApplication } from '@angular/platform-browser';

import { WaitingInterceptor } from './app/core/interceptors/waiting/waiting.interceptor';
import { ErrorInterceptor } from './app/core/interceptors/error-handling';
import { BaseUrlInterceptor } from './app/core/interceptors/api';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient,
  HttpXhrBackend,
} from '@angular/common/http';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { Routes, provideRouter } from '@angular/router';
import { EMPTY, catchError, firstValueFrom, share } from 'rxjs';

const TRANSLATE_MODULE_CONFIG = {
  loader: {
    provide: TranslateLoader,
    useFactory: setupTranslations,
    deps: [HttpClient],
  },
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./app/layout/layout.routing').then(m => m.LAYOUT_ROUTES),
    canActivate: [MsalGuard],
  },
  {
    path: 'error',
    children: [
      {
        path: '',
        loadChildren: () => import('./app/error/error.routing').then(routes => routes.ERROR_ROUTES),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

if (environment.production) {
  enableProdMode();
}
function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('MSAL Angular: ', message);
}

//registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Rd0xiWX1YdXBQQmJY');
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Wd0RjXH9Wc3dUQGZU');

interface CustomerConfiguration {
  msal: any;
  guard: MsalGuardConfiguration;
  interceptor: MsalInterceptorConfiguration;
}

interface ConfigApiResponse {
  names: {
    signUpSignIn?: string;
    resetPassword?: string;
    editProfile?: string;
  };
  authorityDomain: string;
}
function loadConfigurationBasedOnBaseUrl(): Promise<CustomerConfiguration> {
  const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

  return new Promise((resolve, reject) => {
    let promise = firstValueFrom(
      httpClient.get<ConfigApiResponse>(environment.BASE_API_URL + '/api/public/b2c').pipe(
        share(),
        catchError(error => {
          console.error('An error occurred:', error);
          //redirect to error page
          window.location.href = '/error';
          return EMPTY;
        })
      )
    );
    let configuration: CustomerConfiguration;
    promise
      .then((data: ConfigApiResponse) => {
        let msalConfig = createMsalConfig(data.names.signUpSignIn, data.authorityDomain);
        configuration = {
          msal: msalConfig,
          guard: msalGuardConfig,
          interceptor: msalInterceptorConfig,
        };
      })
      .then(() => {
        resolve(configuration);
      });
  });
}

/*
async function loadConfigurationBasedOnBaseUrl(): Promise<CustomerConfiguration> {
  const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

  let promise = firstValueFrom(httpClient.get(environment.BASE_API_URL + '/api/public/b2c').pipe(share()));

  promise.then(data => console.log('promise', data));

  return new Promise((resolve, reject) => {
    let baseUrl = window.location.origin;

    let configuration: CustomerConfiguration;

    if (baseUrl === 'https://customer1.velasuite.com') {
      configuration = {
        msal: <msalConfigTest>,
        guard: msalGuardConfig,
        interceptor: msalInterceptorConfig,
      };
    } else if (
      baseUrl === 'https://cloudvela.velasuite.com' ||
      baseUrl === 'https://yellow-mushroom-0b22dc903-staging.westeurope.3.azurestaticapps.net' ||
      baseUrl === 'https://yellow-mushroom-0b22dc903-dev.westeurope.3.azurestaticapps.net' ||
      baseUrl === 'http://localhost:4200'
    ) {
      configuration = {
        msal: msalConfig,
        guard: msalGuardConfig,
        interceptor: msalInterceptorConfig,
      };
    }

    if (configuration) {
      console.log(JSON.stringify(configuration));
      resolve(configuration);
    } else {
      reject(new Error('Configuration not found for this base URL:' + baseUrl));
    }
  });
}
*/

loadConfigurationBasedOnBaseUrl()
  .then(configuration => {
    bootstrapApplication(AppComponent, {
      providers: [
        importProvidersFrom(
          TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
          ToastrModule.forRoot(),
          // Initiate the MSAL library with the MSAL configuration object
          MsalModule
        ),
        provideRouter(routes),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useValue: new PublicClientApplication({
            auth: configuration.msal.auth,
            cache: configuration.msal.cache,
            system: {
              loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
              },
            },
          }),
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useValue: {
            interactionType: configuration.guard.interactionType,
            authRequest: configuration.guard.authRequest,
            loginFailedRoute: configuration.guard.loginFailedRoute,
          } as MsalGuardConfiguration,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useValue: {
            interactionType: configuration.interceptor.interactionType,
            protectedResourceMap: new Map(configuration.interceptor.protectedResourceMap),
          } as MsalInterceptorConfiguration,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BaseUrlInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: WaitingInterceptor,
          multi: true,
        },
        { provide: 'BASE_API_URL', useValue: environment.BASE_API_URL },
        MsalGuard,
        MsalService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
      ],
    }).catch(err => console.error(err));
  })
  .catch(error => console.error(error));
