import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { WaitingService } from 'src/app/shared/services/waiting.service';

@Injectable()
export class WaitingInterceptor implements HttpInterceptor {
  constructor(private waitingService: WaitingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.waitingService.show();

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.waitingService.hide();
        }
      }),
      catchError(err => {
        this.waitingService.hide();
        return throwError(() => err);
      })
    );
  }
}
