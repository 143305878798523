export namespace ApiUrl {
  export enum LAYOUT {
    SIDEBAR_MENU = '/api/private/menu',
  }

  export enum SHARED {
    CAUSAL_ROLES = '/api/private/causalRoles',
    HOURS = '/api/private/hour',
    HIERARCHY = '/api/private/hierarchy',
    USER_PROFILE = '/api/private/uservela',
  }

  export enum DASHBOARD {
    DASHBOARD = '/api/private/dashboard',
    DOCUMENTS = '/api/private/dashboard/document',
    PUNCH = '/api/private/dashboard/punch',
    GRAPH = '/api/private/dashboard/graph',
    TODO = '/api/private/dashboard/what',
  }

  export enum EVENTS {
    EVENTS = '/api/private/events',
    EVENTS_LIST = '/api/private/events/list',
    EVENT_TYPES = '/api/private/events/eventtype',
    EVENT_FILTER = '/api/private/events/filter',
    DATE_CHANGE = '/api/private/events/datechange',
    CAUSAL = '/api/private/events/causal',
    HOUR = '/api/private/events/hour',
    PUNCH = '/api/private/events/punch',
    TIMESHEET = '/api/private/events/timesheet',
    NOTE = '/api/private/events/note',
    RESULT = '/api/private/events/result',
    ALERT = '/api/private/events/alert',
    CAUSAL_ROLES = '/api/private/events/causalRoles',
  }

  export enum ATTENDANCE {
    TRACKING_SHEET = '/api/private/trackingSheet',
    TRACKING_SHEET_TEAM = '/api/private/trackingSheet/team',
    TRACKING_SHEET_TOTALS = '/api/private/trackingSheet/totals',
    TRACKING_SHEET_SITUATIONS = '/api/private/trackingSheet/situations',
    TRACKING_SHEET_TEAM_SITUATIONS = '/api/private/trackingSheet/teamsituations',
    PRESENTS_ABSENTS = '/api/private/presentabsent',
  }

  export enum EVENTS_TEAM {
    EVENTS = '/api/private/eventsteam',
    LIST = '/api/private/eventsteam/list',
    RESOURCES = '/api/private/eventsteam/resources',
    TYPE = '/api/private/eventsteam/type',
    DATE_CHANGE = '/api/private/eventsteam/datechange',
    EVENT_TYPE = '/api/private/eventsteam/eventtype',
    FILTER = '/api/private/eventsteam/filter',
    CAUSAL = '/api/private/eventsteam/causal',
    HOUR = '/api/private/eventsteam/hour',
    PUNCH = '/api/private/eventsteam/punch',
    TIMESHEET = '/api/private/eventsteam/timesheet',
    NOTE = '/api/private/eventsteam/note',
    APPROVE = '/api/private/eventsteam/approve',
    DENY = '/api/private/eventsteam/deny',
    RESTORE = '/api/private/eventsteam/restore',
    CAUSAL_ROLES = '/api/private/eventsteam/causalRoles',
    DASHBOARD = '/api/private/eventsteam/dashboard',
  }

  export enum DOCUMENTS {
    DOCUMENT = '/api/private/document',
  }

  export enum CONFIGURATION {
    TABLES = '/api/private/configuration/tables',
    ATTENDANCE_HOUR = '/api/private/configuration/attendance/hour',
    ATTENDANCE_PAY_ITEM = '/api/private/configuration/attendance/payitem',
    TIMESHEET_JOB = '/api/private/configuration/timesheet/job',
    TIMESHEET_ACTIVITY = '/api/private/configuration/timesheet/activities',
    EXPENSE_PAYMENT = '/api/private/configuration/expense/payment',
    EXPENSE_USER_CATEGORY = '/api/private/configuration/expense/usercategory',
    EXPENSE_TYPE = '/api/private/configuration/expense/expensetype',
    EXPENSE_ITEM = '/api/private/configuration/expense/expenseitem',
    PAYMENT_DOCUMENT = '/api/private/configuration/expense/paymentdocument',
  }

  export enum EXPENSE {
    BASE = '/api/private/expense',
    ITEM = '/api/private/expense/item',
    PROGRESSIVE_NUMBER = '/api/private/expense/number',
  }
}
