export enum SystemRoutes {
  ERR_401 = '401',
  ERR_404 = '404',
  ERR_500 = '500',
  ABOUT = 'about',
}

export enum MenuRoutes {
  HOME = 'home',
  PLANNER = 'planner',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  ATTENDANCE = 'attendance',
  EXPENSE = 'expense',
  TIMESHEET = 'timesheet',
  DOCUMENTS = 'documents',
  CONFIGURATION = 'configuration',
}

export enum AttendanceModuleRoutes {
  REQUESTS = 'requests',
  APPROVAL = 'aproval',
  TEAM_WORKFORCE = 'teamworkforce',
  PRESENTS = 'presents',
  TRACKING_SHEET = 'trackingsheet',
  TEAM_TRACKING_SHEET = 'teamtrackingsheet',
}

export enum TimesheetModuleRoutes {
  REQUESTS = 'requests',
  APPROVAL = 'timesheetaproval',
  TASKS_STAMP = 'tasksstamp',
}

export enum DocumentsModuleRoutes {
  PERSONAL = 'personal',
  COMMON = 'common',
}

export enum ConfigurationModuleRoutes {
  CONFIGURATION = 'configuration',
  TABLES = 'tables',

  COMMON_HOLIDAY = 'common/holiday',
  COMMON_CLASSIFICATION = 'common/classification',
  COMMON_AREA = 'common/area',
  COMMON_CENTER = 'common/center',
  COMMON_HIERARCHY = 'common/hierarchy',

  ATTENDANCE_HOUR = 'attendance/hour',
  ATTENDANCE_HOUR_DETAIL = 'attendance/hour/detail',
  ATTENDANCE_HOUR_GROUP = 'attendance/hourgroup',
  ATTENDANCE_PROCESS = 'attendance/process',

  TIMESHEET_JOB = 'timesheet/job',
  TIMESHEET_ACTIVITY = 'timesheet/activity',

  EXPENSE_PAYMENT = 'expense/payment',
  EXPENSE_USER_CATEGORY = 'expense/usercategory',
  EXPENSE_TYPE = 'expense/expensetype',
  EXPENSE_ITEM = 'expense/expenseitem',
}

export const ModuleRoutes = {
  ATTENDANCE: AttendanceModuleRoutes,
  TIMESHEET: TimesheetModuleRoutes,
  DOCUMENTS: DocumentsModuleRoutes,
  CONFIGURATION: ConfigurationModuleRoutes,
};

export enum AgendaRoutes {
  PUBLISH = 'publish',
  FOLDERS = 'folders',
  TEAMS = 'teams',
}

export enum TeamWorkforceRoutes {
  WORKFORCE = 'workforce',
  SCHEDULER = 'scheduler',
  REQUESTS = 'requests',
}
