import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { ApiUrl } from '../../enums/api-url.enum';
import { Observable, tap } from 'rxjs';
import { UserInfo } from './user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userInfo = signal<UserInfo>(undefined);
  httpClient = inject(HttpClient);

  setUserProfile(): Observable<UserInfo> {
    return this.httpClient
      .get<UserInfo>(ApiUrl.SHARED.USER_PROFILE)
      .pipe(tap((data: UserInfo) => this.userInfo.set(data)));
  }
}
